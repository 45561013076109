import { Badge } from '../Badge';
import { SpecialProjectType } from '../../lib/graphql.document';

interface PaidBadgeProps {
  isPaid?: boolean;
  specialProjectType?: SpecialProjectType | null;
  small?: boolean;
  className?: string;
}

const PaidBadge = ({ isPaid, specialProjectType, small, className }: PaidBadgeProps) => {
  if (!isPaid) {
    return null;
  }

  return small ? (
    <Badge round className={className}>
      С
    </Badge>
  ) : (
    <Badge className={className}>{specialProjectType === SpecialProjectType.Promo ? 'Спешелти' : 'Спецпроект'}</Badge>
  );
};

export { PaidBadge };
